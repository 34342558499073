import React, {useContext, useState} from "react"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ModalWindow from "./BirthdayModal"
import ContainerLayout from "../../components/ui/containerLayout"
import Button from "../../components/ui/buttons"

import { useI18n } from "../../../locale.js"
import { ServerDataContext } from '../../../serverData';

import greyBrackets from "../../assets/images/elements/greyBrackets.svg"
import greenBrackets from "../../assets/images/elements/greenBrackets.svg"
import fallingStar from "../../assets/images/elements/fallingStar.svg"

const Price = ({ city }) => {
  const breakpoints = useBreakpoint()
  const { details } = useContext(ServerDataContext);
  const { prices } = city;
  const { schedule } = details;
  const timeUp = schedule?.monday[0].to;
  const [showModal, setShowModal] = useState(false)
  const weekdayTitle = useI18n('main.price.weekdays', { time: timeUp || '19' });
  const weekdayPrice = useI18n('main.price.weekdaysDetails');
  const weekendTitle = useI18n('main.price.weekends', { time: timeUp || '19' });
  const weekendPriceTitle = useI18n('main.price.weekendsDetails');
  const allWeek = useI18n('birthday.price.allWeek');
  const currencyText = useI18n('birthday.price.currency');
  const durationText = useI18n('birthday.price.duration');
  const bookText = useI18n('birthday.price.book');

  const isSamePrice = () => {
    if (!schedule) return prices.weekday === prices.weekend;

    if (schedule.monday.length > 1) {
      return schedule.monday[0].price === schedule.monday[1].price === schedule.sunday[0].price;
    } else {
      return schedule.monday[0].price === schedule.sunday[0].price
    }
  };

  return (
    <ContainerLayout id="prices">
      <ModalWindow
        city={city}
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />

      <TitleStyled>
        <b>{useI18n('birthday.price.title')}</b>
      </TitleStyled>

      {breakpoints.small ? null : (
        <FallingStartStyled src={fallingStar} alt="Falling star" />
      )}

      <WrapperStyled>
        {
          !isSamePrice() && (
            <ItemStyled>
              <ItemTitleStyled>{weekdayTitle}</ItemTitleStyled>
              <ItemDescriptionStyled>{weekdayPrice}</ItemDescriptionStyled>
              <ItemBlockStyled>
                <ItemPriceStyled>{schedule ? schedule.monday[0].price : prices.weekday}</ItemPriceStyled>
                <ItemPriceTextStyled>
                  <span>{currencyText}</span>
                  <p>{durationText}</p>
                </ItemPriceTextStyled>
              </ItemBlockStyled>
              <ButtonStyled
                variant="outlined"
                title={bookText}
                onClick={() => setShowModal(!showModal)}
              />
            </ItemStyled>
          )
        }

        <ItemStyled isHovered>
          <ItemTitleStyled>{isSamePrice() ? allWeek : weekendTitle}</ItemTitleStyled>
          <ItemDescriptionStyled>
            {weekendPriceTitle}
          </ItemDescriptionStyled>
          <ItemBlockStyled>
            <ItemPriceStyled>{schedule ? schedule.sunday[0].price : prices.weekend}</ItemPriceStyled>
            <ItemPriceTextStyled>
              <span>{currencyText}</span>
              <p>{durationText}</p>
            </ItemPriceTextStyled>
          </ItemBlockStyled>
          <ButtonStyled
            variant="outlined"
            title={bookText}
            onClick={() => setShowModal(!showModal)}
          />
        </ItemStyled>
      </WrapperStyled>

      <HintTextStyled>
        {useI18n('birthday.price.upfront1')}
        <br /> {useI18n('birthday.price.upfront2')}
      </HintTextStyled>
    </ContainerLayout>
  )
}
export default Price

const TitleStyled = styled.p`
  font-size: 2.5rem;
  line-height: 1.28;
  text-align: center;
  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`
const WrapperStyled = styled.div`
  display: flex;

  margin-top: 5rem;
  gap: 20px;

  @media ${props => props.theme.breakpoints.tablet} {
    flex-direction: column;

    margin-top: 3rem;
  }
`
const ItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 40px;
  max-width: 420px;

  > * {
    z-index: 1;
  }

  &:before {
    content: "";

    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;

    background-image: url(${props =>
      props.isHovered ? greenBrackets : greyBrackets});
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:after {
    content: "";

    width: 100%;
    height: 100%;

    position: absolute;
    bottom: 0;

    background-image: url(${props =>
      props.isHovered ? greenBrackets : greyBrackets});
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }

  &:hover:before,
  &:hover:after {
    background-image: url(${greenBrackets});
  }
  @media ${props => props.theme.breakpoints.medium} {
    padding: 30px 40px;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    padding: 30px 50px;
  }
`

const ItemTitleStyled = styled.div`
  margin-bottom: ${props => (props.noMargin ? null : "1rem")};
  min-height: 55px;

  font-size: 1.4rem;
  text-align: center;
  font-family: ${props => props.theme.fonts.futuraDemi};
  color: ${props => props.theme.colors.green};

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.2rem;
  }
`
const ItemDescriptionStyled = styled.p`
  font-size: 1.1rem;
  line-height: 1.41;
  text-align: center;

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1rem;
  }
`

const ItemBlockStyled = styled.div`
  display: flex;
  align-items: center;

  margin: 20px 0;
`
const ItemPriceStyled = styled.p`
  font-size: 2.8rem;
  font-family: ${props => props.theme.fonts.futuraDemi};
  line-height: 1.41;
`
const ItemPriceTextStyled = styled.div`
  margin-left: 10px;
  span {
    font-size: 1.1rem;
    color: ${props => props.theme.colors.white};
  }
  p {
    font-size: 0.8rem;
    color: ${props => props.theme.colors.green};
  }
`

const ButtonStyled = styled(Button)`
  min-width: 12rem;

  && {
    font-size: 1rem;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`
const FallingStartStyled = styled.img`
  width: 130px;
  height: 70px;

  position: absolute;
  right: -5%;
  top: 6%;
`

const HintTextStyled = styled.p`
  margin: 60px auto 20px;

  font-size: 1rem;
  line-height: 1.1;
  color: #b4b4b4;
  text-align: center;

  @media ${props => props.theme.breakpoints.mobile} {
    margin: 40px auto 20px;

    font-size: 0.9rem;
  }
`
