import React from "react"

import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import scrollTo from "gatsby-plugin-smoothscroll"

import Button from "../../components/ui/buttons"
import ContainerLayout from "../../components/ui/containerLayout"

import { useI18n } from "../../../locale.js"

import peopleBg from "../../assets/images/bg/peopleBirthdayBg.png"
import fallingStar from "../../assets/images/elements/fallingStar.svg"
import flashStar from "../../assets/images/elements/flashStar.png"
import greenCodeStrings from "../../assets/images/elements/greenCodeStrings.png"
import greyCubes from "../../assets/images/elements/greyCubesCloud.png"
import ruler from "../../assets/images/elements/ruler.svg"

const Ladning = () => {
  const breakpoints = useBreakpoint()

  return (
    <ContainerLayoutStyled>
      <BlockStyled>
        <TitleStyled>
          {useI18n('birthday.firstScreen.modern')} <b>{useI18n('birthday.firstScreen.birthday')}</b> {useI18n('birthday.firstScreen.birthdayFor')}
        </TitleStyled>

        <ButtonStyled
          title={useI18n('birthday.firstScreen.wantToPlay')}
          variant="solid"
          defaultSize
          onClick={() => scrollTo("#prices")}
        />
      </BlockStyled>

      <PeopleImageStyled src={peopleBg} alt="People VR" />

      {breakpoints.mobile ? null : (
        <CodeStringsImageStyled src={greenCodeStrings} alt="Matrix code" />
      )}
      {breakpoints.small ? null : <RulerImageStyled src={ruler} alt="Ruler" />}
      {breakpoints.small ? null : (
        <FlashStarImageStyled src={flashStar} alt="Flashing star" />
      )}

      <CubesImageStyled src={greyCubes} alt="Cubes pattern" />
      {breakpoints.tablet ? null : <HintTextStyled>Start game/</HintTextStyled>}
    </ContainerLayoutStyled>
  )
}
export default Ladning

const ContainerLayoutStyled = styled(ContainerLayout)`
  margin-top: 100px;
  @media ${props => props.theme.breakpoints.laptop} {
    margin-top: 80px;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    margin-top: 60px;
  }
  @media ${props => props.theme.breakpoints.small} {
    flex-direction: column-reverse;
  }
`

const BlockStyled = styled.div`
  max-width: 50%;
  margin-right: auto;
  margin-top: 5rem;

  position: relative;

  &:before {
    content: "";
    width: 130px;
    height: 70px;

    position: absolute;
    left: 20%;
    top: -100px;

    background-image: url(${fallingStar});
    background-size: 80%;
    background-repeat: no-repeat;
  }
  @media ${props => props.theme.breakpoints.medium} {
    max-width: 60%;
    z-index: 5;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    max-width: 70%;
  }
  @media ${props => props.theme.breakpoints.small} {
    max-width: 100%;
    margin-top: 0;
    &:before {
      z-index: 1;
      left: 85%;
      top: -60%;
    }
  }
`
const TitleStyled = styled.p`
  margin-bottom: 2.5rem;

  font-size: 2.8rem;
  line-height: 1.14;

  @media ${props => props.theme.breakpoints.laptop} {
    font-size: 2.4rem;
  }

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`

const HintTextStyled = styled.span`
  position: absolute;
  bottom: 70px;

  color: #151c1a;
  font-size: 1.1rem;
  font-family: ${props => props.theme.fonts.futuraDemi};
`
const ButtonStyled = styled(Button)`
  @media ${props => props.theme.breakpoints.mobile} {
    width: 100%;
  }
`

const PeopleImageStyled = styled.img`
  width: 50%;

  position: absolute;
  right: 0%;
  z-index: 1;

  @media ${props => props.theme.breakpoints.small} {
    width: 70%;
    margin-bottom: 2rem;

    position: initial;
    right: unset;
    z-index: 0;
  }
  @media ${props => props.theme.breakpoints.mobile} {
    width: calc(100% + 4rem);
  }
`
const RulerImageStyled = styled.img`
  width: 40%;

  position: absolute;
  right: 0%;
  bottom: 80px;
`

const CubesImageStyled = styled.img`
  width: 180px;
  height: 330px;

  position: absolute;
  right: -15%;
  top: 0%;

  transform: rotate(-90deg);
  object-fit: contain;
`
const CodeStringsImageStyled = styled.img`
  width: 130px;
  height: 320px;
  object-fit: contain;

  position: absolute;
  top: 0;

  @media ${props => props.theme.breakpoints.small} {
    width: 80px;
    height: 230px;
    left: 0;
  }
`
const FlashStarImageStyled = styled.img`
  width: 60px;
  height: 60px;

  position: absolute;
  right: -5%;
  top: 5%;
`
