import React from "react";
import styled from "styled-components";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import scrollTo from "gatsby-plugin-smoothscroll";

import ContainerLayout from "../../components/ui/containerLayout";
import Button from "../../components/ui/buttons";

import { useI18n, useCustomI18n } from "../../../locale.js";

import slashedGreyCubes from "../../assets/images/elements/slashedGreyCubes.svg";
import aroundCircle from "../../assets/images/about/360Circle.svg";
import gamepadCircle from "../../assets/images/about/gamepadCircle.svg";
import pillsCircle from "../../assets/images/about/pillsCircle.svg";
import teamCircle from "../../assets/images/about/teamCircle.svg";
import cosmosBg from "../../assets/images/bg/bdayCosmicBg.jpg";
import fallingStar from "../../assets/images/elements/fallingStar.svg";

const About = () => {
  const breakpoints = useBreakpoint();
  const whatYouGetText = useI18n("birthday.about.whatYouGet");
  const whatYouGetDetails = useI18n("birthday.about.whatYouGetDetails");

  return (
    <WrapStyled>
      <ContainerLayoutStyled>
        <TitleStyled>
          <b>{useI18n("birthday.about.whyUs")}</b>
        </TitleStyled>

        <WrapperStyled>
          <ItemStyled>
            <ItemImageStyled src={teamCircle} alt="Team play" />
            <div>
              <ItemTitleStyled>
                {useI18n("birthday.about.fullFreedom")}
              </ItemTitleStyled>
              <ItemDescriptionStyled>
                {useI18n("birthday.about.freedomDetails")}
              </ItemDescriptionStyled>
            </div>
          </ItemStyled>
          <ItemStyled>
            <ItemImageStyled src={pillsCircle} alt="Atmosphere" />
            <div>
              <ItemTitleStyled>
                {useI18n("birthday.about.atmosphere")}
              </ItemTitleStyled>
              <ItemDescriptionStyled>
                {useI18n("birthday.about.atmosphereDetails")}
              </ItemDescriptionStyled>
            </div>
          </ItemStyled>
          <ItemStyled>
            <ItemImageStyled src={aroundCircle} alt="360 degree" />
            <div>
              <ItemTitleStyled>
                {useI18n("birthday.about.emotions")}
              </ItemTitleStyled>
              <ItemDescriptionStyled>
                {useI18n("birthday.about.emotionsDetails")}
              </ItemDescriptionStyled>
            </div>
          </ItemStyled>
          <ItemStyled>
            <ItemImageStyled src={gamepadCircle} alt="Gamepad" />
            <div>
              <ItemTitleStyled>
                {useI18n("birthday.about.alternative")}
              </ItemTitleStyled>
              <ItemDescriptionStyled>
                {useI18n("birthday.about.alternativeDetails")}
              </ItemDescriptionStyled>
            </div>
          </ItemStyled>
        </WrapperStyled>

        <ButtonStyled
          title={useI18n("birthday.about.wantToBook")}
          variant="solid"
          defaultSize
          onClick={() => scrollTo("#prices")}
        />
        {breakpoints.small ? null : <HintTextStyled>Start game</HintTextStyled>}
      </ContainerLayoutStyled>

      {/* <ContainerLayoutStyled>
        {breakpoints.small ? null : (
          <>
            <FallingStarImageStyled src={fallingStar} alt="Falling start" />

            <TitleStyled>
              <b>{whatYouGetText}</b>
            </TitleStyled>
            <CursiveCubesImageStyled
              src={slashedGreyCubes}
              alt="Cubes pattern"
            />

            <DescriptionStyled>{whatYouGetDetails}</DescriptionStyled>

            <FallingStarLeftImageStyled src={fallingStar} alt="Falling start" />
          </>
        )}

        <LineStepWrapStyled>
          <LineStepItemStyled>
            <LineStepTitleStyled>
              {useI18n("birthday.about.preparation")}
            </LineStepTitleStyled>
            <LineStepTextStyled>
              {useI18n("birthday.about.preparationDetails")}
            </LineStepTextStyled>
            <LineStepTimeStyled>
              <span>20:00</span> {useI18n("birthday.about.minutes")}
            </LineStepTimeStyled>
          </LineStepItemStyled>

          <LineStepItemStyled>
            <LineStepTitleStyled>
              {useI18n("birthday.about.instructions")}
            </LineStepTitleStyled>
            <LineStepTextStyled>
              {useI18n("birthday.about.instructionsDetails")}
            </LineStepTextStyled>
            <LineStepTimeStyled>
              <span>20:00</span> {useI18n("birthday.about.minutes")}
            </LineStepTimeStyled>
          </LineStepItemStyled>

          <LineStepItemStyled>
            <LineStepTitleStyled>
              {useI18n("birthday.about.breakTime")}
            </LineStepTitleStyled>
            <LineStepTextStyled>
              {useI18n("birthday.about.breakTimeDetails")}
            </LineStepTextStyled>
            <LineStepTimeStyled>
              <span>20:00</span>
              {useI18n("birthday.about.minutes")}
            </LineStepTimeStyled>
          </LineStepItemStyled>

          <LineStepItemStyled>
            <LineStepTitleStyled>
              {useI18n("birthday.about.secondPlay")}
            </LineStepTitleStyled>
            <LineStepTextStyled>
              {useI18n("birthday.about.secondPlayDetails")}
            </LineStepTextStyled>
            <LineStepTimeStyled>
              <span>20:00</span> {useI18n("birthday.about.minutes")}
            </LineStepTimeStyled>
          </LineStepItemStyled>

          <LineStepItemStyled>
            <LineStepTitleStyled>
              {useI18n("birthday.about.photoTime")}
            </LineStepTitleStyled>
            <LineStepTextStyled>
              {useI18n("birthday.about.photoTimeDetails")}
            </LineStepTextStyled>
            <LineStepTimeStyled>
              <span>20:00</span> {useI18n("birthday.about.minutes")}
            </LineStepTimeStyled>
          </LineStepItemStyled>
        </LineStepWrapStyled>

        {breakpoints.small ? null : (
          <FallingStarRightImageStyled src={fallingStar} alt="Falling start" />
        )}
      </ContainerLayoutStyled> */}
    </WrapStyled>
  );
};
export default About;

const WrapStyled = styled.div`
  overflow: hidden;
  background-image: url(${cosmosBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  counter-reset: step;

  position: relative;

  > * {
    z-index: 1;
  }

  &:before {
    content: "";

    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;

    background: linear-gradient(
      to bottom,
      ${(props) => props.theme.colors.black},
      15%,
      rgba(3, 3, 3, 0.4)
    );
  }
  &:after {
    content: "";

    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;

    background: linear-gradient(
      to top,
      ${(props) => props.theme.colors.black},
      10%,
      rgba(3, 3, 3, 0.1)
    );
  }
`;

const ContainerLayoutStyled = styled(ContainerLayout)``;

const TitleStyled = styled.p`
  margin-bottom: 2.2rem;

  font-size: 2.5rem;
  line-height: 1.14;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`;
const WrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 5rem;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 2rem;
  }
`;
const ItemStyled = styled.div`
  display: flex;
  align-items: flex-start;

  width: 46%;
  margin: 0 auto 3rem;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 100%;
    margin: 0 0 3rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const ItemImageStyled = styled.img`
  margin-right: 10px;
  margin-top: -15px;
  width: 140px;
  height: 140px;

  @media ${(props) => props.theme.breakpoints.tablet} {
    width: 85px;
    height: 85px;
  }
`;

const ItemTitleStyled = styled.p`
  margin-bottom: 1.5rem;

  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fonts.futuraDemi};

  @media ${(props) => props.theme.breakpoints.small} {
    margin-bottom: 1rem;
  }
`;

const ItemDescriptionStyled = styled.p`
  font-size: 1rem;
  line-height: 1.41;

  @media ${(props) => props.theme.breakpoints.small} {
    margin-bottom: 0.9rem;
  }
`;

const ButtonStyled = styled(Button)`
  @media ${(props) => props.theme.breakpoints.tablet} {
    margin-top: 80px;
  }

  @media ${(props) => props.theme.breakpoints.small} {
    width: 100%;
  }
`;
const HintTextStyled = styled.span`
  margin: auto;

  position: absolute;
  bottom: 40px;

  color: #151c1a;
  font-size: 1.5rem;
  font-family: ${(props) => props.theme.fonts.futuraDemi};
`;

const FallingStarImageStyled = styled.img`
  width: 130px;
  height: 70px;

  position: absolute;
  left: 5%;
  top: 0%;

  object-fit: contain;
`;
const FallingStarLeftImageStyled = styled(FallingStarImageStyled)`
  width: 65px;
  height: 40px;
  top: 65%;
  left: 0;
`;
const FallingStarRightImageStyled = styled(FallingStarLeftImageStyled)`
  right: -5%;
  left: unset;
  top: 55%;
`;
const CursiveCubesImageStyled = styled.img`
  width: 170px;
  height: 28px;

  position: absolute;
  right: -5%;
  top: 8%;
`;

const DescriptionStyled = styled.p`
  max-width: 750px;
  margin: auto;

  font-size: 1.1rem;
  line-height: 1.4;
  text-align: center;

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1rem;
  }
`;
const LineStepWrapStyled = styled.div`
  max-width: 800px;
  margin: 120px auto 60px;

  @media ${(props) => props.theme.breakpoints.medium} {
    max-width: 550px;
  }

  @media ${(props) => props.theme.breakpoints.medium} {
    max-width: 420px;
  }

  @media ${(props) => props.theme.breakpoints.small} {
    padding-left: 20px;
  }
`;
const LineStepItemStyled = styled.div`
  padding: 40px 0 28px;

  position: relative;

  border-bottom: 2px solid ${(props) => props.theme.colors.green};

  &:before {
    content: counter(step);
    counter-increment: step;
    display: block;

    width: 50px;
    height: 50px;

    position: absolute;
    z-index: 1;
    top: 28px;
    left: -73px;

    background-color: ${(props) => props.theme.colors.green};

    text-align: center;
    line-height: 55px;
    font-size: 2.2rem;
    font-weight: bold;
    font-family: serif;
    font-family: ${(props) => props.theme.fonts.courier};
  }
  &:after {
    content: "";
    width: 50px;
    height: calc(100% - 0.5px);

    position: absolute;
    top: -2px;

    border-top: 2px solid ${(props) => props.theme.colors.green};
    border-bottom: 2px solid ${(props) => props.theme.colors.green};
  }
  &:nth-child(2n-1):after {
    border-left: 2px solid ${(props) => props.theme.colors.green};
    left: -49px;
  }
  &:first-child:after {
    bottom: -2px;
    top: auto;
    height: calc(100% - 40px);
    border-top: none;
  }
  &:nth-child(2n):before {
    left: auto;
    right: -69px;
  }
  &:nth-child(2n):after {
    border-right: 2px solid ${(props) => props.theme.colors.green};
    right: -39px;
  }
  &:last-child {
    border-bottom: none;
    &:after {
      top: -2px;
      height: 60px;
      border-bottom: none;
      border-bottom-left-radius: 0;
    }
  }

  @media ${(props) => props.theme.breakpoints.medium} {
    &:after,
    &:last-child:after {
      top: -1.5px;
    }
  }

  @media ${(props) => props.theme.breakpoints.small} {
    padding-left: 40px;
    border-left: 3px solid ${(props) => props.theme.colors.green};
    border-bottom: none;
    &:before,
    &:nth-child(2n):before {
      top: 27px;
      width: 40px;
      height: 40px;
      left: -22px;

      line-height: 44px;
      font-size: 1.4rem;
    }
    &:after {
      content: none;
    }
    &:first-child,
    &:last-child {
      padding-top: 6px;
      &:before {
        top: 0;
      }
    }
    &:last-child {
      border-left: none;
    }
  }
`;
const LineStepTitleStyled = styled.p`
  margin-bottom: 15px;

  font-size: 1.6rem;
  line-height: 1;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.futuraDemi};

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.1rem;
  }
`;

const LineStepTextStyled = styled(DescriptionStyled)`
  text-align: left;
`;
const LineStepTimeStyled = styled.p`
  margin-top: 15px;

  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;

  span {
    color: ${(props) => props.theme.colors.green};
    font-size: 1.2rem;
    font-family: ${(props) => props.theme.fonts.courier};
  }
`;
