import React, { useEffect, useState, forwardRef, useContext } from 'react'

import styled from 'styled-components'
import Modal from 'react-modal'
import Select from 'react-select'
import { navigate } from 'gatsby'
import NumberFormat from 'react-number-format'
import axios from 'axios'

import { useI18n, LocaleContext } from '../../../locale.js';
import { ServerDataContext } from '../../../serverData';

import Button from '../../components/ui/buttons'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import uk from 'date-fns/locale/uk';

import calendarGrey from '../../assets/icons/calendarGrey.png'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

import Recaptcha from 'react-recaptcha';

import moment from "moment";

registerLocale('ru', ru);
registerLocale('en', en);
registerLocale('ua', uk);

// TODO fix booking request

const ModalWindow = ({ showModal, onClose, city }) => {
  const { locale } = useContext(LocaleContext);
  const context = React.useContext(I18nextContext);
  const { details } = useContext(ServerDataContext);
  const [executeRecaptcha, setExecureRecaptcha] = useState(false);

  const [name, setname] = useState('');
  const [comment, setComment] = useState('');
  const [phone, setphone] = useState('');
  const [date, setdate] = useState(null);
  const [location, setlocation] = useState({});
  const [isPhoneValid, setisPhoneValid] = useState(true);
  const [allowEmptyFormatting, setallowEmptyFormatting] = useState(false);

  const locationData = details?.addresses || city.locations;

  const getLocationString = address => {
    const addon = address[context.language]?.address1 ? `, ${address[context.language]?.address1}` : '';
    return address[context.language]?.address + addon;
  }

  useEffect(() => {
    if (locationData.length === 1) {
      setlocation({
        value: locationData[0].companyId,
        label: locationData[0].address ?
          getLocationString(locationData[0].address) :
          locationData[0].address1[context.language]
      });
    } else {
      setlocation({})
    }
  }, [city])

  const reCaptchaCallback = (response) => {
    setExecureRecaptcha(true)
  };

  const options = locationData.map((c) => ({
    label: c.address ? getLocationString(c.address) : c.address1[context.language],
    value: c.companyId
  }))

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <DatePickerInputStyled
      onClick={onClick}
      value={value}
      type='text'
      readOnly={true}
      ref={ref}
      placeholder={useI18n('birthday.modal.date')}
    />
  ))

  const onSubmit = (e) => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha || String(phone.value).length < 10) {
      return
    }

    if (!/^\d{10}$/.test(phone.value)) {
      setisPhoneValid(false)
      return
    }

    const payload = {
      type: 'event',
      phone: phone.value,
      name,
      date: moment(date).format("YYYY-MM-DD"),
      noCallback: false,
      q: window.location.search,
      ...(comment && { details: comment })
    }

    axios
      .post('https://us-central1-vr-crm.cloudfunctions.net/newBookingRequest', {
        companyId: location.value || city.companyId,
        bookingData: payload
      }, {})
      .then(() => {
        // Ping GTM
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({ 'event': 'formsendVR' })
        // Go to Thank You page
        navigate('/' + city.slug + '/thank-you')
      })
  }

  return (
    <ModalStyled
      isOpen={showModal}
      onRequestClose={onClose}
      style={{
        overlay: {
          zIndex: '100',
          backgroundColor: 'rgba(0, 0, 0, 0.8)'
        }
      }}
      ariaHideApp={false}
    >
      <ModalBackgroundStyled onClick={onClose} />
      <ModalBodyStyled>
        <CloseButtonStyled onClick={onClose} />
        <ModalTitleStyled>{useI18n('birthday.modal.request')}</ModalTitleStyled>
        <FormStyled onSubmit={onSubmit}>
          <InputStyled
            type='text'
            placeholder={useI18n('birthday.modal.name')}
            value={name}
            onChange={(e) => setname(e.target.value)}
          />

          <NumberFormat
            customInput={InputStyled}
            placeholder={useI18n('birthday.modal.phone')}
            format='+38 (###) ###-##-##'
            mask='_'
            type='text'
            value={phone.formattedValue}
            allowEmptyFormatting={allowEmptyFormatting}
            onFocus={() => {
              if (!allowEmptyFormatting) {
                setallowEmptyFormatting(true)
              }
            }}
            onBlur={() => {
              setallowEmptyFormatting(false)
            }}
            onValueChange={(value) => {
              setphone(value)
              setisPhoneValid(true)
            }}
            style={isPhoneValid ? {} : { border: '1px solid #eb516d' }}
          />

          <DatePickerWrapStyled>
            <DatePicker
              minDate={new Date()}
              selected={date}
              dateFormat='dd.MM.yyyy'
              onChange={(date) => setdate(date)}
              onChangeRaw={(e) => e.preventDefault()}
              placeholderText={useI18n('birthday.modal.date')}
              customInput={<CustomInput />}
              locale={context.language}
            />
            <CalendarIconStyled src={calendarGrey} alt='Calendar' />
          </DatePickerWrapStyled>

          <SelectStyled
            options={options}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
            value={options.find((c) => c.value === location.value)}
            onChange={(option) => setlocation(option)}
            classNamePrefix='select'
            isSearchable={false}
            placeholder={useI18n('birthday.modal.choose_club')}
          />

          <TextAreaStyled
            value={comment}
            type='text'
            placeholder={useI18n('birthday.modal.note')}
            rows={4}
            onChange={e => setComment(e.target.value)}
          />

          <Recaptcha
            sitekey="6Lf31ZklAAAAAPpgpM9s16vvfzPatOBpp_Vv_KwN"
            render="explicit"
            verifyCallback={reCaptchaCallback}
          />
          <div style={{ marginBottom: '20px' }}></div>
          <ButtonStyled
            disabled={!executeRecaptcha || String(phone.value).length < 10}
            variant='solid'
            title={useI18n('birthday.modal.book')}
            onClick={onSubmit}
            type='submit'
          />
        </FormStyled>
      </ModalBodyStyled>
    </ModalStyled>
  )
}

export default ModalWindow

const ModalStyled = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
`
const ModalBackgroundStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 550px;
  width: 100%;
  margin: auto;
  padding: 80px;

  position: relative;

  background-color: #fff;

  z-index: 1;

  @media ${(props) => props.theme.breakpoints.small} {
    height: 100vh;
    padding: 40px 60px;
    max-width: 100%;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    padding: 40px 20px;
  }
`
const FormStyled = styled.form`
  display: flex;
  flex-direction: column;

  width: 100%;
`
const CloseButtonStyled = styled.button`
  width: 25px;
  height: 25px;
  padding: 0px;

  position: absolute;
  right: 32px;
  top: 32px;

  background: none;
  border: none;

  opacity: 0.3;
  transition: 0.3s all;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    content: ' ';
    height: 26px;
    width: 2px;

    position: absolute;
    top: 0;
    left: 11px;

    background-color: ${(props) => props.theme.colors.green};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    right: 20px;
    top: 20px;
  }
`

const ModalTitleStyled = styled.p`
  margin-bottom: 50px;

  font-size: 1.8rem;
  color: ${(props) => props.theme.colors.black};
  font-family: ${(props) => props.theme.fonts.futuraDemi};

  @media ${(props) => props.theme.breakpoints.small} {
    font-size: 1.6rem;
  }
`

const InputStyled = styled.input`
  padding: 6px 15px;
  margin-bottom: 25px;

  border: 1px solid #6e6e6e;

  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.black};
  resize: none;

  outline: none;
`
const TextAreaStyled = styled.textarea`
  padding: 6px 15px;
  margin-bottom: 25px;
  height: 80px;

  border: 1px solid #6e6e6e;

  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.black};
  resize: none;

  outline: none;
`

const SelectStyled = styled(Select)`
  .select__control {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    cursor: pointer;

    padding: 4px 15px 4px 7px;
    margin-bottom: 25px;
    margin-top: 25px;

    border: 1px solid #6e6e6e !important;
    border-radius: unset;

    font-size: 0.9rem;
    color: ${(props) => props.theme.colors.black};

    &:after {
      content: '';

      width: 0;
      height: 0;
      margin-left: auto;

      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #6e6e6e;
      z-index: 1;
    }
  }
  .select__single-value {
    color: ${(props) => props.theme.colors.black};
    margin: 0;
  }
  .select__menu {
    border-radius: 0;
    padding: 0;
    margin-top: -20px;
  }

  .select__option {
    background-color: ${(props) => props.theme.colors.white}!important;
    cursor: pointer;
    color: ${(props) => props.theme.colors.black};
    &:hover {
      color: ${(props) => props.theme.colors.green};
    }
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    .select__menu {
      margin-top: -20px;
    }
  }
`

const ButtonStyled = styled(Button)`
  @media ${(props) => props.theme.breakpoints.small} {
    width: 100%;
    margin: auto;
  }
`

const DatePickerWrapStyled = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .react-datepicker-wrapper {
    z-index: 1;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${(props) => props.theme.colors.green};
  }
`
const CalendarIconStyled = styled.img`
  position: absolute;
  right: 10px;
`
const DatePickerInputStyled = styled.input`
  width: 100%;
  padding: 6px 30px 6px 15px;

  border: 1px solid #6e6e6e;
  z-index: 1;
  background-color: transparent;

  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.black};

  outline: none;
`
