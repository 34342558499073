import React, {useState} from "react"

import styled from "styled-components"

import NavBar from "../../components/navbar"
import Contacts from "../../components/contacts"
import Games from "../../components/games"
import Footer from "../../components/footer"

import Landing from "./Landing"
import Description from "./Description"
import About from "./About"
import Price from "./Price"
import Faq from "./Faq"
import Seo from "../../components/seo"
import { ServerDataContext } from '../../../serverData';

const Birthday = ({ pageContext }) => {
  return (
    <ServerDataContext.Consumer>
      {({ dataLoaded, getData }) => {
        if (!dataLoaded) getData(pageContext.companyId);
        return (
          <ContainerStyled id="birthday">
            <NavBar city={pageContext} isBirthday/>
            <Landing/>
            <Description/>
            <About/>

            <Price city={pageContext}/>
            <Games city={pageContext} isBirthday={true}/>
            <Faq/>

            <Contacts city={pageContext} isBirthday/>
            <Footer city={pageContext}/>
          </ContainerStyled>
        );
      }}
    </ServerDataContext.Consumer>
  )
}

export const Head = ({ pageContext }) => (
  <>
    <SeoData context={pageContext} />
    <script
      dangerouslySetInnerHTML={{
        __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '1258133411516313'); 
fbq('track', 'PageView');
            `
      }}
    />
    <noscript>
      <img height="1" width="1"
        src="https://www.facebook.com/tr?id=1258133411516313&ev=PageView
&noscript=1"/>
    </noscript>
    {!!pageContext.fbPersonalId &&
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '${pageContext.fbPersonalId}'); 
fbq('track', 'PageView');
            `
          }}
        />
        <noscript>
          <img height="1" width="1"
            src={`https://www.facebook.com/tr?id=${pageContext.fbPersonalId}&ev=PageView
&noscript=1`} />
        </noscript>
      </>
    }
  </>
)
const SeoData = ({ context }) => {
  const [lang, setLang] = useState('ua')
  console.log("Context", context)
  return (
          <Seo
          context={context}
          title={`Дні народження у Matrix VR ${context.name[lang]}`}
          description={`У твоєму розпорядженні буде весь ігровий простір клубу, де ти зможеш провести час великою компанією, і всі ви зможете насолодитися ігровим процесом. Ви зможете спробувати велику кількість ігор, граючи в одній команді, один проти одного або кожен індивідуально.`}
         />
  )
}

export default Birthday

const ContainerStyled = styled.div`
  min-height: 100vh;
  background: #030303;
  overflow: hidden;
`
