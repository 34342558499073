import React from "react"

import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ContainerLayout from "../../components/ui/containerLayout"
import { useI18n } from "../../../locale.js"

import fallingStar from "../../assets/images/elements/fallingStar.svg"
import greyCubes from "../../assets/images/elements/greyCubesCloud.png"
import gridBg from "../../assets/images/elements/gridBg.svg"

const Description = () => {
  const breakpoints = useBreakpoint()

  return (
    <ContainerLayout>
      {breakpoints.small ? null : (
        <GreyCubesImageStyled src={greyCubes} alt="Grey cubes pattern" />
      )}
      <TitleStyled>
        {useI18n('birthday.description.title')}
        <br />
        <b>{useI18n('birthday.description.title2')}</b>
      </TitleStyled>

      {/* todo add margin */}
      <DescriptionStyled>
        {useI18n('birthday.description.details')}
      </DescriptionStyled>
      <DescriptionStyled>
        {useI18n('birthday.description.detail2')}
      </DescriptionStyled>
      <DescriptionStyled>
        {useI18n('birthday.description.detail3')}
      </DescriptionStyled>

      {breakpoints.tablet ? null : (
        <FallingStartImageStyled src={fallingStar} alt="Falling star" />
      )}

      <SquaresImageStyled src={gridBg} alt="Grid pattern" />
    </ContainerLayout>
  )
}
export default Description

const TitleStyled = styled.p`
  margin-bottom: 2.2rem;

  font-size: 2.5rem;
  line-height: 1.14;
  text-align: left;

  b {
    color: ${props => props.theme.colors.green};
  }
  @media ${props => props.theme.breakpoints.small} {
    text-align: center;
    font-size: 1.7rem;
  }
`

const DescriptionStyled = styled.p`
  max-width: 750px;
  margin: auto;

  font-size: 1.1rem;
  line-height: 1.4;
  text-align: left;

  z-index: 1;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  @media ${props => props.theme.breakpoints.small} {
    font-size: 1rem;
    text-align: center;
  }
`

const GreyCubesImageStyled = styled.img`
  width: 180px;
  height: 330px;

  position: absolute;
  left: -20%;
  top: 0%;

  transform: rotate(-90deg);
  object-fit: contain;
`

const FallingStartImageStyled = styled.img`
  width: 130px;
  height: 70px;

  position: absolute;
  right: 5%;
  top: 10%;

  object-fit: contain;
`
const SquaresImageStyled = styled.img`
  width: 480px;
  height: 480px;

  position: absolute;
  right: -35%;
  top: 20%;

  object-fit: contain;

  @media ${props => props.theme.breakpoints.tablet} {
    width: 260px;
    height: 260px;

    right: -20%;
    top: 60%;
  }
  @media ${props => props.theme.breakpoints.small} {
    right: -35%;
  }
`
