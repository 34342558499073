import React, { useState, useContext } from "react"

import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import ContainerLayout from "../../components/ui/containerLayout"
import BottomImagesBlock from "../../components/bottomBlockImages"

import { LocaleContext } from "../../../locale.js";
import { i18n } from '../../i18n.js'

import slashedGreyCubes from "../../assets/images/elements/slashedGreyCubes.svg"
import greyCubes from "../../assets/images/elements/greyCubesCloud.png"
import checkedCubesCloud from "../../assets/images/elements/checkedCubesCloud.png"
import arrowIcon from "../../assets/icons/arrowRight.png"
import crossIcon from "../../assets/icons/crossWhite.png"
import { I18nextContext } from "gatsby-plugin-react-i18next"


const numberOfQuestions = 12

const Faq = () => {
  const breakpoints = useBreakpoint()
  const [selectedQuestion, setselectedQuestion] = useState(null)
  const { locale } = useContext(LocaleContext);
  const context = React.useContext(I18nextContext);

  const toggleAnswer = index => {
    if (selectedQuestion === index) {
      return setselectedQuestion(null)
    }
    setselectedQuestion(index)
  }

  return (
    <ContainerLayoutStyled id="faq">
      <TitleStyled>
        <b>FAQ</b>
      </TitleStyled>

      <BlockStyled>
        {breakpoints.small ? null : (
          <CubesImageStyled src={checkedCubesCloud} alt="Cubes pattern" />
        )}

        {Array(numberOfQuestions).fill(null).map((k, i) =>
          <ItemStyled key={i}>
            <ItemTitleStyled role="presentation" onClick={() => toggleAnswer(i)}>
              <p>{i18n[context.language].birthday.faq[`question${i + 1}`]}</p>
              <ItemTitleIconStyled
                src={selectedQuestion === i ? crossIcon : arrowIcon}
                alt="Toggle answer"
              />
            </ItemTitleStyled>
            <ItemDescriptionStyled visible={selectedQuestion === i}>
              {i18n[context.language].birthday.faq[`answer${i + 1}`]}
            </ItemDescriptionStyled>
          </ItemStyled>
        )}
      </BlockStyled>

      <BottomImagesBlock />
    </ContainerLayoutStyled>
  )
}
export default Faq

const ContainerLayoutStyled = styled(ContainerLayout)`
  position: relative;
  padding-bottom: 0;

  &:before {
    content: "";
    width: 170px;
    height: 28px;
    position: absolute;
    left: 0%;
    top: 8%;

    background-image: url(${slashedGreyCubes});
    background-size: 80%;
    background-repeat: no-repeat;
  }

  &:after {
    content: "";

    width: 250px;
    height: 380px;

    position: absolute;
    top: 0;
    right: -12%;

    background-image: url(${greyCubes});
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: right top;
  }

  @media ${props => props.theme.breakpoints.small} {
    &:before,
    &:after {
      display: none;
    }
  }
`
const TitleStyled = styled.p`
  margin-bottom: 100px;

  font-size: 2.5rem;
  line-height: 1.14;
  text-align: center;
  z-index: 1;

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7rem;
  }
`

const BlockStyled = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 700px;
  width: 100%;
  margin: auto;

  position: relative;
`

const CubesImageStyled = styled.img`
  width: 250px;
  height: 380px;

  position: absolute;
  left: -10px;
  top: 0;
  transform: translateX(-100%) rotate(180deg);
`

const ItemStyled = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  border-bottom: 1px solid grey;

  &:first-child {
    border-top: 1px solid grey;
  }
`
const ItemTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 14px 10px 14px 0;

  font-size: 1.3rem;
  line-height: 1.25;
  cursor: pointer;

  @media ${props => props.theme.breakpoints.mobile} {
    font-family: ${props => props.theme.fonts.futuraDemi};
    font-size: 1rem;
  }
`
const ItemDescriptionStyled = styled.div`
  margin: ${({ visible }) => (visible ? "10px 0px 15px" : "0")};
  max-height: ${({ visible }) => (visible ? "initial" : "0")};

  font-size: 1.1rem;
  line-height: 1.4;
  color: #b5b5b5;

  transform: ${({ visible }) =>
    visible ? "translateY(0)" : "translateY(-10px)"};
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  transition: 0.2s ease-in;

  @media ${props => props.theme.breakpoints.mobile} {
    font-size: 1rem;
  }
`

const ItemTitleIconStyled = styled.img`
  align-self: flex-start;
  width: 32px;
  object-fit: contain;
`
